import React, { useState, useRef, useEffect } from "react";
import { LinearProgress } from "@mui/material";
import { audioError } from "../TextToSpeech/audioError";
import { Box, Button, Typography } from "@ellevation/backpack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ttsTransform } from "../TextToSpeech/ttsTransform";
import { pocAssessmentFetchHelper } from "lib/api/pocAssessmentAPI";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { usePoc } from "pages/POCAssessmentPage/PocContextProvider";
import { postAssessmentEvent } from "lib/api/pocAssessmentAPI";

export const ListeningBar = ({
	text,
	id,
	audioUrl,
	recordedAudioDuration,
	domain
}) => {
	const [progress, setProgress] = useState(0);
	const [playClicked, setPlayClicked] = useState(null);
	const audioRef = useRef(new Audio());
	const {
		currentContentIndex,
		registerPlayingAudio,
		isAudioPlaying,
		setIsAudioPlaying,
		setListeningPlays
	} = usePoc();
	useEffect(() => {
		const loadAudio = async () => {
			let audioSource = `data:audio/mp3;base64,${audioError}`;
			if (audioUrl) {
				audioSource = audioUrl;
			} else {
				const transformedText = ttsTransform(text);
				if (transformedText) {
					const response = await pocAssessmentFetchHelper(
						`text_speech`,
						"POST",
						{
							text: transformedText
						}
					);
					if (response.ok) {
						const result = await response.json();
						audioSource = result?.audioUrl;
					}
				}
			}

			audioRef.current = new Audio(audioSource);
			audioRef.current.addEventListener("ended", handleEndedAsEvent);
			audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
		};
		loadAudio();

		/* istanbul ignore next */
		return () => {
			handleEnded();
			if (audioRef.current) {
				audioRef.current.pause();
			}
		};
	}, [text]);

	/* istanbul ignore next */
	const handleTimeUpdate = () => {
		const duration = recordedAudioDuration
			? recordedAudioDuration
			: audioRef.current.duration;
		setProgress(Math.min(audioRef.current.currentTime / duration, 1.0) * 100);
	};
	/* istanbul ignore next */
	const handleEndedAsEvent = () => {
		// only do this when the audio ends on its own
		setListeningPlays(prev => prev + 1);
		handleEnded();
	};
	/* istanbul ignore next */
	const handleEnded = () => {
		setPlayClicked(false);
		setIsAudioPlaying(false);
		setProgress(0);
	};

	const togglePlay = async () => {
		setPlayClicked(prev => !prev);
		setIsAudioPlaying(true);
		audioRef.current.play();
		registerPlayingAudio("tts", id);
		if (audioUrl) {
			await postAssessmentEvent(
				"listen_spoken_response",
				currentContentIndex,
				domain
			);
		} else {
			await postAssessmentEvent(
				"listening_audio_played",
				currentContentIndex,
				domain
			);
		}
	};

	const formatTime = seconds => {
		const minutes = Math.floor(seconds / 60);
		const secs = Math.floor(seconds % 60);
		return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
	};
	return (
		<Box
			sx={{
				display: "flex",
				width: "inherit",
				py: 2,
				alignItems: "center",
				gap: 1
			}}>
			<Button
				disabled={isAudioPlaying && true}
				sx={{
					minWidth: "auto",
					width: 48,
					height: 48,
					borderRadius: "70%"
				}}
				onClick={togglePlay}>
				<FontAwesomeIcon icon={faPlay} style={{ width: 16, height: 16 }} />
			</Button>
			{recordedAudioDuration ? (
				<Typography variant="body2" sx={{ width: "90px", fontSize: "18px" }}>
					{formatTime(audioRef.current.currentTime)} /{" "}
					{formatTime(recordedAudioDuration)}
				</Typography>
			) : (
				playClicked && (
					<Typography variant="body2" sx={{ width: "90px", fontSize: "18px" }}>
						{formatTime(audioRef.current.currentTime)} /{" "}
						{formatTime(audioRef.current.duration)}
					</Typography>
				)
			)}

			<Box sx={{ flexGrow: 1 }}>
				<LinearProgress
					variant="determinate"
					value={progress}
					sx={{
						height: 4,
						borderRadius: 2
					}}
				/>
			</Box>
		</Box>
	);
};
