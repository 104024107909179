/* istanbul ignore next */
export const ttsTransform = text => {
	try {
		// filter out non-string or number values
		if (!["string", "number"].includes(typeof text)) {
			return null;
		}

		const sanitizedText = text.toString().trim();

		return sanitizedText;
	} catch (error) {
		return null;
	}
};
