export const actionTypes = {
	SET_INDEX_OFFSET: "SET_INDEX_OFFSET",
	SET_CURRENT_CONTENT_INDEX: "SET_CURRENT_CONTENT_INDEX",
	SET_RESPONSES: "SET_RESPONSES",
	UPDATE_RESPONSES: "UPDATE_RESPONSES",
	REGISTER_PLAYING_AUDIO: "REGISTER_PLAYING_AUDIO",
	SET_LISTENING_PLAYS: "SET_LISTENING_PLAYS",
	SET_IS_AUDIO_PLAYING: "SET_IS_AUDIO_PLAYING"
};

export const pocReducer = (state, action) => {
	switch (action.type) {
		case actionTypes.SET_LISTENING_PLAYS:
			return { ...state, listeningPlays: action.payload };
		case actionTypes.SET_IS_AUDIO_PLAYING:
			return { ...state, isAudioPlaying: action.payload };
		case actionTypes.SET_INDEX_OFFSET:
			return { ...state, indexOffset: action.payload };
		case actionTypes.SET_CURRENT_CONTENT_INDEX:
			return { ...state, currentContentIndex: action.payload };
		case actionTypes.SET_RESPONSES:
			return { ...state, responses: action.payload };
		case actionTypes.UPDATE_RESPONSES:
			return {
				...state,
				responses: state.responses.map((item, index) =>
					index === action.payload.index
						? {
								...item,
								question: {
									...item.question,
									...action.payload.response
								}
							}
						: item
				)
			};
		case actionTypes.REGISTER_PLAYING_AUDIO:
			return {
				...state,
				playingAudioType: action.playingAudioType,
				playingAudioId: action.playingAudioId
			};
		default:
			return state;
	}
};
