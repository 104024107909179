import React, { createContext, useContext, useReducer } from "react";
import { pocReducer } from "./PocReducer";

export const PocStateContext = createContext();
export const PocDispatchContext = createContext();

export const PocContextProvider = ({ children }) => {
	const initialState = {
		indexOffset: 0,
		currentContentIndex: 0,
		responses: [],
		playingAudioType: null,
		playingAudioId: null,
		listeningPlays: 0,
		isAudioPlaying: false
	};
	const [state, dispatch] = useReducer(pocReducer, initialState);

	return (
		<PocStateContext.Provider value={state}>
			<PocDispatchContext.Provider value={dispatch}>
				{children}
			</PocDispatchContext.Provider>
		</PocStateContext.Provider>
	);
};

const usePocState = () => {
	const context = useContext(PocStateContext);
	_throwMissingContext(context);
	return context;
};

const usePocDispatch = () => {
	const context = useContext(PocDispatchContext);
	_throwMissingContext(context);
	return context;
};

const _throwMissingContext = context => {
	/* istanbul ignore next */
	if (context === undefined) {
		throw new Error("usePOC must be used within an Provider");
	}
};

export const usePoc = () => {
	const state = usePocState();
	const dispatch = usePocDispatch();

	return {
		indexOffset: state.indexOffset,
		currentContentIndex: state.currentContentIndex,
		responses: state.responses,
		playingAudioType: state.playingAudioType,
		playingAudioId: state.playingAudioId,
		listeningPlays: state.listeningPlays,
		setListeningPlays: /* istanbul ignore next */ value => {
			dispatch({ type: "SET_LISTENING_PLAYS", payload: value });
		},
		isAudioPlaying: state.isAudioPlaying,
		setIsAudioPlaying: value => {
			dispatch({ type: "SET_IS_AUDIO_PLAYING", payload: value });
		},
		setIndexOffset: value => {
			dispatch({ type: "SET_INDEX_OFFSET", payload: value });
		},
		updateCurrentContentIndex: value => {
			dispatch({ type: "SET_CURRENT_CONTENT_INDEX", payload: value });
		},
		setResponses: value => {
			dispatch({ type: "SET_RESPONSES", payload: value });
		},
		updateResponses: (index, value) => {
			dispatch({
				type: "UPDATE_RESPONSES",
				payload: {
					index: index,
					response: { response: value }
				}
			});
		},
		registerPlayingAudio: /* istanbul ignore next */ (
			playingAudioType,
			playingAudioId
		) =>
			dispatch({
				type: "REGISTER_PLAYING_AUDIO",
				playingAudioType,
				playingAudioId
			})
	};
};
